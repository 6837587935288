import useSWR from "swr";
import API from "@lib/api/api";
import { Game } from "./use-slots-list";
interface SlotsListResults {
  slots: Array<Game>;
  "live-casino": Array<Game>;
  baccarat: Array<Game>;
  roulette: Array<Game>;
  originals: Array<Game>;
  blackjack: Array<Game>;
  "game-shows": Array<Game>;
  cases: Array<Game>;
  sports: Array<Game>;
}
export const useSlotsSearch = (searchTerm: string) => {
  const {
    data,
    error,
    isLoading
  } = useSWR<SlotsListResults>(searchTerm.length >= 3 ? ["public/search", searchTerm] : null, async ([url, term]) => {
    const response = await API.post(url, {
      search: term
    });
    return response.data?.results || {};
  }, {
    revalidateOnFocus: false,
    shouldRetryOnError: false
  });
  return {
    results: data,
    isLoading,
    error
  };
};