import { GhostSlot } from "@components/GhostSlots";
import SlotsList from "@components/slots-list";
import useLanguage from "@hooks/useLanguage";
import { useSlotsSearch } from "@hooks/useSlotsSearch";
import InputItem from "@items/InputItem";
import { useDebounce } from "@lib/tools/helpers";
import st from "@styles/components/SearchModal.module.scss";
import dynamic from "next/dynamic";
import { useMemo, useState } from "react";
import { useRecentGames } from "../recent-games";
import ItemListSearchModal from "./ItemListSearchModal";
const Baccarat = dynamic(() => import("@assets/icons/games/Baccarat"), {
  ssr: false
});
const Blackjack = dynamic(() => import("@assets/icons/games/Blackjack"), {
  ssr: false
});
const CaseOpenIcon = dynamic(() => import("@assets/icons/games/CaseOpenIcon"), {
  ssr: false
});
const GameShowIcon = dynamic(() => import("@assets/icons/games/GameShowIcon"), {
  ssr: false
});
const LiveGamesIcon = dynamic(() => import("@assets/icons/games/LiveGamesIcon"), {
  ssr: false
});
const Roulette = dynamic(() => import("@assets/icons/games/Roulette"), {
  ssr: false
});
const Slots = dynamic(() => import("@assets/icons/games/Slots"), {
  ssr: false
});
const SearchIcon = dynamic(() => import("@assets/icons/general/SearchIcon"), {
  ssr: false
});
const SportsLogo = dynamic(() => import("@assets/icons/sports/SportsLogo"), {
  ssr: false
});
const SvgSecondaryRainbetLogo = dynamic(() => import("@components/Svg/SvgStore").then(mod => mod.SvgSecondaryRainbetLogo), {});
const LoadingSpinner = () => {
  return <>
      <h4 style={{
      marginBottom: "1.75rem"
    }}>
        Searching...
      </h4>
      <div style={{
      position: "relative",
      display: "flex",
      gap: 10
    }}>
        {Array.from({
        length: 7
      }).map((_, i) => <GhostSlot key={i} />)}
      </div>
    </>;
};
export default function SearchModal() {
  const [searchValue, setSearchValue] = useState("");
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const {
    data: recentGames
  } = useRecentGames();
  const L = useLanguage(["common", "meta", "UserProfile"]);
  const getCategoryIcon = category => {
    switch (category) {
      case "originals":
        return <SvgSecondaryRainbetLogo width="20" height="20" fillColor="rgb(113, 121, 165)" styles={{
          marginBottom: "-10px",
          marginTop: "-10px"
        }} />;
      case "slots":
        return <Slots />;
      case "cases":
        return <CaseOpenIcon />;
      case "live-casino":
        return <LiveGamesIcon />;
      case "baccarat":
        return <Baccarat />;
      case "roulette":
        return <Roulette />;
      case "blackjack":
        return <Blackjack />;
      case "game-shows":
        return <GameShowIcon />;
      case "sports":
        return <SportsLogo />;
      default:
        return null;
    }
  };
  const {
    results: searchResults,
    isLoading: loading
  } = useSlotsSearch(debouncedSearchValue);

  // Sort categories to ensure "originals" comes first and "slots" comes second
  const sortedCategories = useMemo(() => {
    if (!searchResults) return [];
    const categories = Object.keys(searchResults);
    return categories.sort((a, b) => {
      if (a === "originals") return -1;
      if (b === "originals") return 1;
      if (a === "slots") return -1;
      if (b === "slots") return 1;
      return 0;
    });
  }, [searchResults]);
  return <div className={st["search-modal"]} data-sentry-component="SearchModal" data-sentry-source-file="index.jsx">
      <div className={st["top-container"]}>
        <h2>{L("search")}</h2>

        <div className={st["search-input-container"]}>
          <InputItem name={"walletAddress"} value={searchValue} method={setSearchValue} autoFocus classType={["search", "modal-inputs", "white-placeholder", "search-modal"]} placeholder={L("start_typing")} type={"text"} icon={<SearchIcon width={16} height={16} />} data-sentry-element="InputItem" data-sentry-source-file="index.jsx" />
        </div>
      </div>
      <div className={st["bottom-container"]}>
        {debouncedSearchValue.length >= 3 && searchResults?.length === 0 && !loading ? <p className={st["no-results"]}>No Results</p> : null}

        {loading && <div className={st["no-results"]}>
            <LoadingSpinner />
          </div>}

        {searchResults && sortedCategories.map(category => {
        const categoryResults = searchResults[category];
        if (categoryResults.length > 0) {
          return <ItemListSearchModal key={category} items={categoryResults} title={category} resultCountNumber={category === "cases" || category === "sports" ? 3 : 7} icon={getCategoryIcon(category)} caseOpen={category === "cases"} sports={category === "sports"} />;
        }
        return null;
      })}
        <div className={st["item-list-modal-container"]} data-slots>
          {recentGames?.length ? <SlotsList isStatic heading="recently_played_games" carousel={7} initialData={{
          count: recentGames.length,
          games: recentGames
        }} /> : null}
        </div>
      </div>
    </div>;
}