import Image from "next/image";
import st from "../../styles/components/CaseOpenModalCard.module.scss";
import Link from "next/link";
import ValueDisplay from "@items/ValueDisplay";
import useConvert from "@hooks/useConvert";
export default function CaseOpenModalCard({
  name,
  price,
  image,
  url
}) {
  const {
    getConvertedAmount
  } = useConvert();
  return <Link href={`/casino/originals/case-opening/${url.toLowerCase().replace(/\s+/g, "-")}`} data-sentry-element="Link" data-sentry-component="CaseOpenModalCard" data-sentry-source-file="CaseOpenModalCard.jsx">
      <div className={st["case-open-modal-container"]}>
        <Image src={image} alt={name} width={80} height={80} data-sentry-element="Image" data-sentry-source-file="CaseOpenModalCard.jsx" />
        <div className={st["case-open-modal-desc"]}>
          <div className={st["title"]} style={{
          textOverflow: "ellipsis",
          width: "90%"
        }}>
            {name}
          </div>
          <span>
            <ValueDisplay size="mid" raw amount={getConvertedAmount(price, {
            round: {
              direction: "roundHalfUp"
            }
          })} data-sentry-element="ValueDisplay" data-sentry-source-file="CaseOpenModalCard.jsx" />
          </span>
        </div>
      </div>
    </Link>;
}