import NextArrowSquareIcon from "@assets/icons/general/NextArrowSquareIcon";
import PrevArrowSquareIcon from "@assets/icons/general/PrevArrowSquareIcon";
import SlotLink from "@components/slot-link_old";
import { Game } from "@hooks/use-slots-list";
import { useBreakpoint } from "@hooks/useMobile";
import st from "@styles/components/SearchModal.module.scss";
import React, { memo, useEffect, useMemo, useState } from "react";
import CaseOpenModalCard from "./CaseOpenModalCard";
import SportsModalCard from "./SportsModalCard";

// We extend Game for additional flexibility with the API response
interface SlotItem extends Omit<Game, "id"> {
  id: string; // API returns id as string in search results
}
interface CaseItem {
  name: string;
  url: string;
  image: string;
  price: string;
}
interface SportItem {
  name: string;
  route: string;
}
interface ItemListSearchModalProps {
  title: string;
  icon: React.ReactNode;
  items: SlotItem[] | CaseItem[] | SportItem[];
  resultCountNumber?: number;
  caseOpen?: boolean;
  sports?: boolean;
}
function ItemListSearchModal({
  title,
  icon,
  items = [],
  resultCountNumber = 4,
  caseOpen = false,
  sports = false
}: ItemListSearchModalProps) {
  const breakpoint = useBreakpoint({
    breakPointThree: 600
  });
  const [startIndex, setStartIndex] = useState(0);
  const resultCount = useMemo(() => {
    if (caseOpen && breakpoint == 1) {
      return 2;
    } else if (caseOpen && breakpoint >= 2) {
      return 1;
    } else if (breakpoint === 1) {
      return 5;
    } else if (breakpoint === 2) {
      return 3;
    } else if (breakpoint === 3) {
      return 3;
    } else {
      return resultCountNumber;
    }
  }, [breakpoint, resultCountNumber, caseOpen]);
  const resultsPerPage = resultCount;
  const handleNext = () => {
    const newIndex = startIndex + resultsPerPage;
    if (newIndex < items.length) {
      setStartIndex(newIndex);
    } else {
      setStartIndex(0);
    }
  };
  const handlePrevious = () => {
    const newIndex = startIndex - resultsPerPage;
    if (newIndex >= 0) {
      setStartIndex(newIndex);
    } else {
      setStartIndex(Math.max(0, items.length - resultsPerPage));
    }
  };
  const formatTitle = (str: string) => {
    return str.replace(/-/g, " ").replace(/\b\w/g, firstChar => firstChar.toUpperCase());
  };
  useEffect(() => {
    setStartIndex(0);
  }, [items]);
  return <div className={st["item-list-modal-container"]} data-list={title} data-sentry-component="ItemListSearchModal" data-sentry-source-file="ItemListSearchModal.tsx">
      <div className={st["title-carousel-container"]}>
        <div className={st["title-section-container"]}>
          {icon}
          <h3>{formatTitle(title)}</h3>
        </div>
        <div className={st["next-prev-container"]}>
          <div className={`${st["arrow-icon"]} ${startIndex === 0 ? st["deactive"] : ""}`} onClick={startIndex === 0 ? undefined : handlePrevious}>
            <PrevArrowSquareIcon data-sentry-element="PrevArrowSquareIcon" data-sentry-source-file="ItemListSearchModal.tsx" />
          </div>
          <div className={`${st["arrow-icon"]} ${startIndex + resultsPerPage >= items.length ? st["deactive"] : ""}`} onClick={startIndex + resultsPerPage >= items.length ? undefined : handleNext}>
            <NextArrowSquareIcon data-sentry-element="NextArrowSquareIcon" data-sentry-source-file="ItemListSearchModal.tsx" />
          </div>
        </div>
      </div>
      <div className={st["slots-list-container"]} style={{
      gridAutoColumns: `calc(100% / ${resultCount} - 10px * ${resultCount - 1} / ${resultCount})`
    }}>
        {items.slice(startIndex, startIndex + resultCount).map((item, i) => {
        if (caseOpen) {
          const caseItem = item as CaseItem;
          return <CaseOpenModalCard key={i + "-case-" + caseItem.name} image={caseItem.image} name={caseItem.name} price={caseItem.price} url={caseItem.url} />;
        } else if (sports) {
          const sportItem = item as SportItem;
          return <SportsModalCard key={i + "-sport-" + sportItem.name} name={sportItem.name} url={sportItem.route} />;
        } else {
          const slotItem = item as SlotItem;
          // Convert string ID to number for the Game interface
          return <SlotLink item={{
            ...slotItem,
            id: parseInt(slotItem.id, 10)
          }} key={slotItem.id} />;
        }
      })}
      </div>
    </div>;
}
export default memo(ItemListSearchModal);