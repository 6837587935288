import st from "../../styles/components/CaseOpenModalCard.module.scss";
import Link from "next/link";
import BaseBall from "../../assets/icons/sports/Baseball";
import CSGO from "../../assets/icons/sports/CSGO";
import LOL from "../../assets/icons/sports/LOL";
import Dota from "../../assets/icons/sports/Dota";
import Valorant from "../../assets/icons/sports/Valorant";
import StarCraft from "../../assets/icons/sports/StartCraft";
import Boxing from "../../assets/icons/sports/Boxing";
import Tennis from "../../assets/icons/sports/Tennis";
import IceHockey from "../../assets/icons/sports/IceHockey";
import MMA from "../../assets/icons/sports/MMA";
import AmericanFootball from "../../assets/icons/sports/AmericanFootball";
import Basketball from "../../assets/icons/sports/Basketball";
import Soccer from "../../assets/icons/sports/Soccer";
export default function SportsModalCard({
  name,
  url
}) {
  const getSportsIcon = value => {
    let icon = null;
    switch (value) {
      case "cs":
        icon = <CSGO />;
        break;
      case "lol":
        icon = <LOL />;
        break;
      case "dota2":
        icon = <Dota />;
        break;
      case "valorant":
        icon = <Valorant />;
        break;
      case "starcraft2":
        icon = <StarCraft />;
        break;
      case "american-football":
        icon = <AmericanFootball />;
        break;
      case "soccer":
        icon = <Soccer />;
        break;
      case "baseball":
        icon = <BaseBall />;
        break;
      case "boxing":
        icon = <Boxing />;
        break;
      case "tennis":
        icon = <Tennis />;
        break;
      case "ice-hockey":
        icon = <IceHockey />;
        break;
      case "mma":
        icon = <MMA />;
        break;
      case "basketball":
        icon = <Basketball />;
        break;
      default:
        icon = null; // Set default icon to null for unknown values
        break;
    }
    return icon;
  };
  function formatGameName(name) {
    const words = name.split("-").map(word => word.charAt(0).toUpperCase() + word.slice(1));
    return words.join(" ");
  }
  return <Link href={url ? url : "/sportsbook"} data-sentry-element="Link" data-sentry-component="SportsModalCard" data-sentry-source-file="SportsModalCard.jsx">
      <div className={`${st["case-open-modal-container"]} ${st["sports"]}`}>
        <div className={st["sports-icon-container"]}>{getSportsIcon(name)}</div>
        <div className={st["case-open-modal-desc"]}>
          <div className={st["sports-title"]}>{formatGameName(name)}</div>
        </div>
      </div>
    </Link>;
}