import useToken from "@hooks/useToken";
import { NODE_API } from "@lib/api/api";
import { logError } from "@lib/tools/logger";
import useSWR from "swr";
const RECENT_GAMES_KEY = "/v1/user/recent-games";
export const getRecentGames = (token: string, headers = {}) => {
  if (!token) return Promise.resolve([]);
  return NODE_API.get(RECENT_GAMES_KEY, {
    headers: {
      Authorization: `Bearer ${token}`,
      ...headers
    }
  }).then(res => {
    return res.data || [];
  }).catch(err => {
    logError(err);
    return [];
  });
};
export const useRecentGames = (initialData?: any[]) => {
  const token = useToken();
  return useSWR(token ? RECENT_GAMES_KEY : null, () => getRecentGames(token), {
    keepPreviousData: true,
    fallbackData: initialData,
    revalidateOnMount: !initialData?.length,
    revalidateOnFocus: false
  });
};