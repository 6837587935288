import Image from "next/image";
import Link from "next/link";
import { memo, useMemo } from "react";
import useLanguage from "@hooks/useLanguage";
import { classnames, mergeClasses } from "@lib/tools/helpers";
import st from "@styles/components/SlotsList.module.scss";
import { WorldLockIcon } from "@assets/icons/general/WorldLockIcon";
import PlayIcon from "@/assets/icons/general/play-icon";
import { Game } from "@hooks/use-slots-list";
interface SlotLinkProps {
  item: Game;
  lastSlotStyle?: boolean;
  index?: number;
  translateLeft?: boolean;
  className?: string;
}
function SlotLink({
  item,
  lastSlotStyle,
  index,
  translateLeft
}: SlotLinkProps) {
  const L = useLanguage(["Slots"]);
  const edgeFirst = lastSlotStyle && index === 0;
  const lastStyle = edgeFirst ? st["last-slot-slider--first"] : lastSlotStyle ? st["last-slot-slider"] : "";
  const type = item?.type || "originals";
  const url = item?.url;
  const isAnyLive = type !== "slots" && type !== "originals";
  const isLive = type === "live";
  const inHouse = type === "originals";
  const isSports = type === "sport";
  const href = useMemo(() => ({
    pathname: isSports ? `/sportsbook/${item?.route}` : isAnyLive ? isLive ? `/casino/live/${url}` : `/casino/live/${type}/${url}` : `/casino/${type}/${url}`
  }), [isAnyLive, isLive, isSports, item?.route, type, url]);
  const hasCustomBanner = item?.custom_banner && item?.custom_banner !== "";
  const src = item?.custom_banner || item?.icon || "/Images/fallback-image.png";
  return <Link prefetch={false} href={href} {...mergeClasses(lastStyle, classnames(st, item?.region_blocked ? "quick-link-content-blocked" : "quick-link-content", translateLeft && "translate-left"))} data-sentry-element="Link" data-sentry-component="SlotLink" data-sentry-source-file="slot-link_old.tsx">
      <div className={`${st["slot-image"]} flex flex-col justify-center`}>
        {!item?.region_blocked && <PlayIcon />}
      </div>

      {src && <div className={st["slot-image-container"]}>
          <Image alt={item?.name} src={src} fill sizes="200px" style={{
        objectFit: "cover"
      }} />
          {!hasCustomBanner && !inHouse && item?.producer !== "" && item?.producer !== undefined && <div className={st["slot-image-overlay"]}>
                <span>{item?.producer}</span>
              </div>}
        </div>}

      {!inHouse ? item?.region_blocked && <div className={st["game-details-display"]}>
              <div className={`${st["region-blocked-overlay"]} flex flex-col items-center`}>
                <WorldLockIcon />
                <h3 className={st["region-blocked-text"]}>
                  {L("not_available_region")}
                </h3>
              </div>
            </div> : null}
    </Link>;
}
export default memo(SlotLink);